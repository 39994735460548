@import "../../variables.scss";

.calendar-strip-container {
    // width: 95%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .calendar-rows {
        display: flex;
        overflow: hidden;
        scroll-behavior: smooth;
        //  padding-right: 20px;
        flex: 1;
        .day-box {
            padding: 5px 10px;
            min-width: 60px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            margin: 0px 2px;
            cursor: pointer;
            border: none;
            background: white;
            transition: all 0.3s ease-in;
            .day {
                font-size: 1.3rem;
                font-weight: 400;
                cursor: pointer;
            }

            .day-name {
                font-size: 0.8rem;
                font-weight: 300;
                cursor: pointer;
            }
            &.active {
                background: var(--primary) !important;
                color: white;
            }
            &:hover {
                background: #f7f7f7;
            }
        }
    }
    .arrow-button {
        // position: absolute;
        // top: 0;
        // height: 100%;
        background: inherit;
        border: none;
        border-radius: 6px;
        display: flex;
        align-self: normal;
        justify-content: center;
        align-items: center;
        &:hover {
            background: #f7f7f7;
        }
        &.left {
            margin-right: 10px;
            // left: -25px;
        }
        &.right {
            margin-left: 10px;
            // right: -25px;
        }
    }

    .date-range-loader {
        background: inherit;
        font-size: 0.9rem;
        border-radius: 6px;
        margin: 0px 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-in;
    }
}
