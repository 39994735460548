@import "../../variables.scss";
@import "../../mixins.scss";

.timer {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: var(--primary);
    @include respond-below(sm) {
        font-size: 1.2rem;
    }
    img {
        height: 30px;
        padding-right: 10px;
        @include respond-below(sm) {
            height: 25px;
        }
    }
    &.warning {
        color: $danger;
    }
    
}
