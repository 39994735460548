@import "../../variables.scss";
@import "../../mixins.scss";

.mob-actions-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 1rem;
    background: white;
    z-index: 1000;
    width: 100%;
    border-top: 1px solid #e3e3e3;
    @include respond-above(lg) {
        display: none;
    }
}
