@import "../../variables.scss";

.counter {
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
        padding: 0.375rem 0.75rem;
        color: white !important;
        &:disabled {
            cursor: not-allowed;
            pointer-events: visible;
        }
    }
    .value {
        font-size: 1.4rem;
        margin: 0;
        padding: 0 18px;
    }
}