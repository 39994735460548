@import "../../variables.scss";

.offline-toastr {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
    text-align: center;
    padding: 0.4rem;
    font-size: 0.9rem;
    color: white;
    background-color: $danger;
    z-index: 1500;
}

.Toaster__alert {
    border-radius: 0.3rem !important;
    padding: 0 !important;
    padding-right: 35px !important;
}

.toaster-title {
    font-size: 0.85rem;
    font-weight: 600;
    margin-bottom: 4px;
}

.toaster-message {
    font-size: 0.85rem;
}

.toaster-icon {
    height: 1rem;
    width: 1rem;
    margin-right: 0.4rem;
}

.toaster-box {
    padding: 0.7rem 0.8rem;
    min-width: 14rem;
    max-width: 18rem;
}

.toaster-message-box {
    cursor: pointer;
    border-radius: 0.5rem;
    background: white;
    box-shadow: rgba(52, 58, 64, 0.15) 0px 1px 10px 0px;
}

.Toaster__alert_close {
    top: 0 !important;
    right: 0 !important;
    font-size: 1.3rem !important;
    font-weight: 400 !important;
}

.Toaster__alert_close:focus {
    box-shadow: none !important;
    outline: none;
}
