@import "../../variables.scss";

.card-brand-image {
    padding: 0.5rem 1rem;
    background-origin: content-box;
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
}

.bambora-form-control {
    box-shadow: none;
    border-color: var(--primary) !important;
}

.card-holder-name::placeholder, .postal-code::placeholder {
    color: rgb(117, 117, 117) !important;
}

.card-brand-icon {
    position: absolute;
    right: 10px;
    height: 22px;
    top: 10px;
}
