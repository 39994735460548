@import "../../variables.scss";

.tabs {
  margin: 0 auto;
  width: 70%;
  text-align: center;
  margin-top: 15vh;
}

.tab-buttons {
  padding: 10px;
}

.tab-button {
  margin-right: 0.8em;
  font-size: 1rem;
  font-weight: 500;
  background: transparent;
  border: none;
  outline: none;
  padding: 8px 20px;
  cursor: pointer;
  color: rgba($dark, 0.6);
  -webkit-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
  border-bottom: 2px solid transparent;
  &:focus {
    outline: none !important;
  }
  &.active {
    border-bottom: 2px solid;
    color: $dark;
  }
}
.tabs-box {
  padding: 1.8rem 0.5rem 0 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e3e3e3;

  .tab-round {
    font-weight: 500;
    font-size: 0.95rem;
    background: transparent;
    border: none;
    outline: none;
    position: relative;
    margin: 0px 18px;
    color: $dark;
    opacity: 0.6;
    cursor: pointer;
    padding: 6px 4px;
    &:focus {
      outline: none !important;
    }
    &.active {
      border-bottom: 2px solid var(--primary);
      opacity: 1;
    }
    &:first-child {
      margin-left: 0px;
    }
  }
  .activeStatus {
    font-family: "roboto";
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 0.75rem;
    border-radius: 10rem;
    min-width: 1rem;
    min-height: 1rem;
    max-width: 2.1rem;
    display: inline-block;
    background-color: var(--primary);
    color: white;
    align-self: center;
    padding: 2px 6px;
    font-weight: 400;
    line-height: 1.2;
  }
}
.tab-content-box {
  //  padding: 1rem;
  // border-top: 1px solid rgba($border-color, 0.3);
}
