@import "../../variables.scss";
@import "../../mixins.scss";

.ticketing-container {
    width: 75% !important;

    @include respond-below(xl) {
        width: 97% !important;
    }

    @include respond-below(lg) {
        padding-bottom: 5rem;
    }

    .event-details-badge {
        border-radius: 8px;
        padding: 1.1rem;
        margin-bottom: 3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #e2e2e2;
        box-shadow: 2px 2px 3px #0000001a;
        @include respond-below(sm) {
            flex-direction: column;
            align-items: flex-start;
            white-space: nowrap;
            padding: 1rem;
            display: block;
            margin-bottom: 1.25rem;
            .mob-pad {
                padding-top: 15px;
            }

            @include respond-below(xsm) {
                white-space: normal;
            }
        }

        .button {
            margin: 0.25rem;
        }

        //     visit-website button hover
        // .website-btn {
        //     &:hover {
        //         background: #e6e6e6ea;
        //         background: $primary;
        //     }
        // }
    }

    .selected-date {
        font-weight: 500;
        font-size: 1.2rem;
    }
    .status-tags {
        float: right;
        span {
            padding: 0px 10px;
            font-size: 12px;
            color: #8d8b8c;
            font-weight: 300;
            &::before {
                content: " ";
                width: 8px;
                height: 8px;
                border-radius: 50%;
                display: inline-block;
                margin-right: 7px;
            }
            &.available {
                &::before {
                    background-color: #179817;
                }
            }
            &.not-available {
                &::before {
                    background-color: #888888;
                }
            }
            &.fast-filling {
                &::before {
                    background-color: #ff8103;
                }
            }
        }
    }

    .top-bar-sticky {
        position: sticky;
        top: 70px;
        background: white;
        z-index: 1000;
        padding-top: 25px;
        @include respond-below(sm) {
            top: 55px;
        }
    }

    .selected-details-box {
        padding-top: 3rem;
        padding-bottom: 2rem;
        text-align: right;
        label {
            font-size: 1.1rem;
        }
        @include respond-below(lg) {
            display: none;
        }
    }

    .details-container {
        background: #f9f9f9;
        padding: 20px;
        border-radius: 8px;
        max-height: calc(100vh - 120px);
        overflow-y: scroll;
        img {
            border-radius: 8px;
        }
        p {
            font-size: 0.85rem;
            line-height: 1.7;
            font-weight: 400;
        }
    }

    .addmission-details {
        h6 {
            font-size: 0.95rem;
        }

        ul {
            padding-left: 1.4rem;
            li {
                line-height: 1.8;
                font-size: 0.9rem;
            }
            p {
                font-size: 0.8rem;
                font-weight: 300;
                opacity: 0.7;
            }
        }
    }

    .right-sticky {
        position: sticky;
        top: 100px;
        margin-bottom: 15px;
    }

    .right-box {
        max-width: 450px;
        @include respond-above(xl) {
            padding-left: 50px;
        }
        @include respond-between(lg, xl) {
            padding-left: 20px;
        }
        @include respond-below(lg) {
            display: none;
        }
    }

    .cart-badge {
        position: absolute;
        background: var(--primary);
        height: 25px;
        width: 25px;
        border-radius: 50%;
        font-size: 12px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -10px;
        right: 9px;
    }
}
.billing-details {
    label {
        font-size: 0.9rem;
        line-height: 1.8rem;
    }
}

.ticket-success-badge {
    background: $success;
    border-radius: 8px;
    padding: 1.5rem;
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    @include respond-below(sm) {
        flex-direction: column;
        .mob-pad {
            padding-top: 15px;
        }
    }
    .content-box {
        display: flex;
    }
    .content-box-inner {
        padding-left: 15px;
    }
}

.moduurn-field {
    border-radius: 8px;
    padding: 1.2rem 1rem;
    margin-bottom: 1.3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(#00000088, #00000088), url("../../assets/images/moduurn-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @include respond-below(sm) {
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem;
        margin-bottom: 1.25rem;
        .mob-pad {
            padding-top: 15px;
        }
    }

    .modurrn-text {
        margin-right: 3rem;
        color: white;

        @include respond-below(sm) {
            margin: 0;
        }
    }
}

.details-box {
    border-radius: 5px;
    border: 1px solid #f1f1f1;
    margin-bottom: 1.3rem;
    .customer-details {
        border-bottom: 1px solid #f1f1f1;
    }
    .inner-box {
        padding: 1rem;
        .head {
            text-transform: uppercase;
            font-size: 0.8rem;
            padding-bottom: 10px;
            opacity: 0.5;
        }
        label,
        .label {
            font-size: 0.9rem;
            font-weight: 300;
            padding-bottom: 0.35rem;
        }
        .highlight {
            font-weight: 400;
        }
        .success-text {
            font-weight: 400;
            color: $success;
        }
    }
    .order-details {
        display: flex;

        .order-labels {
            min-width: 150px;
            padding-right: 15px;
            label {
                font-size: 0.8rem;
            }
        }
    }
}

.close-icon {
    cursor: pointer;
    height: 1rem;
}

.close-div {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
}

.ticket-modal {
    .qr-container {
        background-color: var(--primary);
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        min-height: 300px;
        .inner-qr-container {
            position: relative;
            top: 50px;
        }
        .ticket-title {
            font-size: 1.3rem;
            padding: 0 15px;
            color: white;
        }

        .qr-code {
            margin-top: 20px;
            max-height: 250px;
        }

        .arrow-btn {
            height: 60px;
            width: 60px;
            margin-bottom: 25px;
            background-color: whitesmoke;
            border-radius: 50%;
            border: none;
            box-shadow: rgb(0 0 0 / 15%) 0px 1px 12px;
            transition: 0.3s ease-in-out;

            &:hover {
                box-shadow: rgb(0 0 0 / 30%) 0px 1px 12px;
            }
        }

        .qr-button {
            position: absolute;
            bottom: 0;
            justify-content: space-between;
            display: flex;
            width: 100%;
            padding: 0px 30px;

            button:disabled,
            button[disabled] {
                cursor: not-allowed;
                .button-img {
                    opacity: 0.6;
                }
            }
        }

        .col-centered {
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
    }

    .content-conatiner {
        padding: 70px 20px 50px;
        .ticket-modal-label {
            font-size: 0.75rem;
            font-weight: 300;
            opacity: 0.5;
            text-transform: uppercase;
        }

        .col-details {
            padding-bottom: 15px;
            label {
                font-size: 0.85rem;
            }
        }
        .logo-watermark {
            height: 30px;
            opacity: 0.4;
        }

        .arrow-btn {
            height: 60px;
            width: 60px;
            background-color: #ffffff;
            border-radius: 50%;
            border: none;
            box-shadow: 0 1px 12px rgb(0 0 0 / 8%);
            transition: 0.3s ease-in-out;

            &:hover {
                box-shadow: 0 1px 12px rgb(0 0 0 / 13%);
            }
        }

        .qr-button-mob {
            bottom: 0;
            justify-content: space-evenly;
            display: flex;
            width: 100%;
            padding: 20px 0px;

            button:disabled,
            button[disabled] {
                .button-img {
                    opacity: 0.6;
                }
            }
        }
    }
}

.ticket-instructions {
    font-size: 0.85rem;
    line-height: 1.5rem;
    font-weight: 300;
}

.page-loader {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.host-list {
    ul {
        padding-left: 0px !important;
        li {
            list-style: none;
            p {
                font-size: 0.85rem !important;
                font-weight: 400 !important;
                opacity: 1 !important;
            }
        }
    }
}
