@import "../../variables.scss";
@import "../../mixins.scss";

.ticket-card {
    background: #f7f7f7;
    min-height: 100px;
    display: flex;
    position: relative;
    flex-direction: row;
    border-radius: 8px;
    margin: 15px 0;
    transition: all 0.3s ease-in;
    @include respond-below(sm) {
        flex-direction: column;
    }

    &:hover {
        box-shadow: 1px 1px 6px 0px #8888883b;
    }

    .card-left {
        padding: 1.2rem;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        align-items: inherit;
    }

    .date-box {
        display: flex;
        justify-content: space-between;
        font-size: 0.85rem;
        font-weight: 400;
        opacity: 0.6;
        text-transform: capitalize;
    }
    .sub-label {
        text-transform: uppercase;
        font-size: 0.8rem;
        opacity: 0.6;
    }
    .qr-code {
        height: 85px;
    }

    &.preview {
        @include respond-below(sm) {
            flex-direction: row;
        }
        cursor: pointer;

        .date-box {
            @include respond-below(sm) {
                flex-direction: column;
            }
        }
    }

    .card-right {
        border-left: 0.1em dashed #e3e3e3;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        width: 200px;
        padding: 1.1rem;
        flex: none;
        position: relative;
        &::before,
        &::after {
            content: "";
            position: absolute;
            display: block;
            width: 0.9em;
            height: 0.9em;
            background: #fff;
            border-radius: 50%;
            left: -0.5em;
        }
        &::before {
            top: -0.4em;
        }
        &::after {
            bottom: -0.4em;
        }
        @include respond-below(sm) {
            width: 100%;
            border-left: none;
            border-top: 0.1em dashed #e3e3e3;
            border-top-right-radius: 0px;
            border-bottom-left-radius: 8px;
            &::after {
                top: -0.4em;
                right: -0.5em;
                left: unset;
                bottom: unset;
            }
        }
        .counter-container {
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            &.sold-out {
                text-align: center;
                color: $danger;
            }
            &.not-available {
                font-size: 0.8rem;
                text-align: center;
                color: $warning;
                font-weight: 300;
            }
        }
    }

    .preview-card-right {
        border-left: 0.1em dashed #e3e3e3;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1.1rem;
        flex: none;
        position: relative;
        &::before,
        &::after {
            content: "";
            position: absolute;
            display: block;
            width: 0.9em;
            height: 0.9em;
            background: #fff;
            border-radius: 50%;
            left: -0.5em;
        }
        &::before {
            top: -0.4em;
        }
        &::after {
            bottom: -0.4em;
        }

        @include respond-above(md) {
            width: 200px;
        }
    }

    .title-box {
        flex: 1;
        display: flex;
        align-items: top;
        flex-direction: row;
        justify-content: space-between;
        .name {
            font-size: 1rem;
            padding-right: 15px;
        }
        .price {
            font-size: 1rem;
            color: var(--primary);
        }
    }
    p {
        font-size: 0.75rem;
        font-weight: 300;
        line-height: 1.7;
        padding-top: 5px;
        opacity: 0.5;
        // width: 90%;
        margin-bottom: 0px;
    }
}
