.root-loader {
    background: hsla(0, 0%, 0%, 0.288);
    height: 100vh;
    z-index: 1500;
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .loader-box {
        height: 150px;
        width: 200px;
        background-color: white;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        font-size: 0.95rem;
        font-weight: 400;
        padding: 25px;
    }
}
