@import "../../variables.scss";

.error-page {
    min-height: calc(100vh - 60px);
    color: $dark;
    display: flex;
    align-items: center;
    justify-content: center;
    .error-box {
        text-align: center;
        padding-bottom: 60px;
        .error-code {
            font-size: 9rem;
            font-weight: 500;
        }
        .error-description {
            font-size: 1.2rem;
            font-weight: 400;
        }
    }
}

.event-error-page {
    min-height: calc(108vh - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .error-field {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .error-msg {
        text-align: center;
        color: slategrey;
        font-size: 1.4rem;
        line-height: 2.5rem;
        font-weight: 500;
    }
    .error-text {
        margin-top: 2em;
    }
    h3 {
        text-align: center;
        color: gray;
        margin-bottom: 0;
    }
}
