$primary: #008a46;
$secondary: #19c4bb;
$success: #179817;
$info: #0dcaf0;
$warning: #ef5d04;
$danger: #ee3647;
$light: #f5f5f5;
$dark: #231f20;
$background-color: white;

