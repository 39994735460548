@import "../../variables.scss";

.cart {
    .cart-title {
        text-transform: uppercase;
        font-size: 0.9rem;
    }
    .clear-link {
        float: right;
        transition: all 0.3s ease-in;
    }
    .close-item {
        margin-left: 10px;
        display: inline-flex;
        padding: 7px;
        border: 1px solid #dddddd;
        border-radius: 3px;
        img {
            cursor: pointer;
            height: 12px;
        }
    }
    .grand-total-row {
        border-top: 1px solid #f1f1f1;
        font-size: 1.1rem;
        padding-top: 1rem;
        .total {
            font-size: 1.3rem;
            font-weight: 500;
            text-align: right;
            padding-top: 1rem;
        }
    }

    table td {
        padding: 0.7rem 0.5rem;
        &:first-child {
            padding-left: 0px;
        }
        &:last-child {
            padding-right: 0px;
        }
    }
}
