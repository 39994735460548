@import "../../variables.scss";

.avatar-box {
    display: block;
    position: relative;
    .avatar {
        justify-content: center;
        align-items: center;
        display: flex;
        color: white;
        font-size: 0.85em;
        font-weight: 500;
        background-color: var(--primary);
    }
    .avatar-image {
        border-radius: 50%;
        object-fit: cover;
    }
}
