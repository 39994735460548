@import "../../variables.scss";
@import "../../mixins.scss";

.time-date-switcher {
    border-radius: 5px;
    border: 1px solid #f1f1f1;
    margin-bottom: 1.3rem;

    .date-switcher {
        padding: 0rem;
        border-right: 1px solid #f1f1f1;
        text-transform: uppercase;
        @include respond-below(sm) {
            width: 100%;
        }
    }

    .dropdown {
        width: 100% !important;
    }

    .dropdown-menu {
        width: 100% !important;
    }

    .time-switcher {
        padding: 0rem;
        text-transform: uppercase;
        position: relative;
        @include respond-below(sm) {
            width: 100%;
        }
    }
    .switcher-value {
        font-size: 0.85rem;
        font-weight: 400;
        padding: 0.2rem 1rem 1rem;
        &.lg {
            font-size: 0.95rem;
            @include respond-below(sm) {
                font-size: 0.85rem;
            }
        }
    }
    .switcher-label {
        padding: 1rem 1rem 0;
        font-size: 0.75rem;
        font-weight: 300;
        opacity: 0.5;
    }

    .dropdown-box {
        border: none;
        padding: 0;
        cursor: pointer;
        &:hover {
            box-shadow: none;
        }
    }
    .drop-arrow {
        position: absolute;
        right: 0;
        top: 0;
        &.open {
            transform: rotate(180deg);
        }
    }
    .slot-drop-menu {
        margin: -1rem;
        border: 1px solid #f1f1f1;
        border-radius: 6px;
        margin-top: 0px;
        padding: 5px 0;
        .Dropdown-option {
            padding: 10px 1rem;
            &:hover {
                background-color: var(--primary);
                color: white;
            }
        }
    }
}
