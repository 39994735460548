@import "../../variables.scss";
@import "../../mixins.scss";

.time-slot-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    width: 100%;

    @include respond-below(sm) {
        justify-content: space-between;
        padding: 0 20px;
    }

    .time-slot {
        background: inherit;
        border: 1px solid #e2e2e2;
        font-size: 1.1rem;
        padding: 0.8rem 1.7rem;
        border-radius: 6px;
        margin: 8px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-in;

        &:hover {
            background: #f7f7f7;
        }
        &.available {
            color: #179817;
        }
        &.fast-filling {
            color: #ff8103;
        }
        &.not-available {
            color: #888888;
            opacity: 0.7;
            cursor: not-allowed;
        }
        &.active {
            background-color: var(--primary);
            color: white;
        }

        @include respond-below(sm) {
            width: 130px;
            white-space: nowrap;
        }

        @include respond-below(xsm) {
            width: 110px;
        }
    }
    .time-slot-loader {
        background: inherit;
        font-size: 0.9rem;
        border-radius: 6px;
        margin: 8px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-in;
    }
}
