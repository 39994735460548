@import "./variables.scss";
@import "./mixins.scss";

@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;1,400&display=swap");

// $body-bg: white; //bg colour
// $body-color: $dark; //font-colour

// /* make the customizations */
// $theme-colors: (
//     "primary": $primary,
//     "secondary": $secondary,
//     "success": $success,
//     "info": $info,
//     "warning": $warning,
//     "danger": $danger,
//     "light": $light,
//     "dark": $dark,
// );

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

body {
    font-family: "Rubik", sans-serif;
}

hr {
    background-color: #e3e3e3;
    opacity: 1;
}

.pointer {
    cursor: pointer;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-right {
    text-align: right !important;
}

.btn {
    border-radius: 6px;
    padding: 0.6rem 1.5rem;
}

.btn-sm {
    padding: 0.4rem 1.5rem;
}

.btn-primary {
    color: white;
    background: var(--primary);
    border-color: var(--primary);
    &:hover,
    &:active,
    &:focus {
        color: white;
        background: var(--primary);
        border-color: var(--primary);
        box-shadow: none;
    }
    &:active:focus {
        box-shadow: none;
    }
    &:disabled,
    &.disabled {
        color: white;
    }
}

.btn-primary:disabled,
.btn-primary.disabled {
    background: var(--primary);
    border-color: var(--primary);
}

.btn-link {
    color: var(--primary);
    &:hover {
        color: var(--primary);
        text-decoration: none;
    }
    &:focus {
        box-shadow: none;
    }
}

.btn-moduurn {
    background-color: #e02725;
    border-radius: 6px;
    border: none;
    color: white;
    margin-right: 1rem;
    padding: 0.4rem 1.5rem;
    white-space: nowrap;
    text-align: center;
}

.custom-scroll-bar {
    /* width */
    &::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #eeeeee;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgb(190, 190, 190);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: rgb(151, 151, 151);
    }
}

.modal-content {
    border: none;
}

.form-box {
    background: #f7f7f7;
    border: 1px solid#E3E3E3;
    padding: 2rem;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    @include respond-below(sm) {
        padding: 1rem;
    }

    .form-title {
        font-size: 1.3rem;
        font-weight: 500;
        margin-bottom: 0.7rem;
    }
    .form-group {
        margin-bottom: 1.5rem;
    }
    .form-label {
        margin-bottom: 0rem;
        font-size: 0.9rem;
    }
    .form-control {
        border-color: #e3e3e3;
        padding: 0.5rem 1rem;
        &::placeholder {
            color: #a7a5a6;
            opacity: 1;
            font-size: 0.9rem;
            font-weight: 300;
        }
        &:focus {
            box-shadow: none;
            border-color: var(--primary);
        }
        &:invalid,
        &.is-invalid {
            border-color: $danger !important;
        }
    }

    .invalid-feedback {
        margin-top: 0.2rem;
        font-size: 0.8em;
        font-weight: 300;
    }
}

.form-check-input:checked {
    background-color: var(--primary);
    border-color: var(--primary);
}

.opac-0 {
    opacity: 0;
}

.opac-10 {
    opacity: 0.1;
}

.opac-20 {
    opacity: 0.2;
}

.opac-30 {
    opacity: 0.3;
}

.opac-40 {
    opacity: 0.4;
}

.opac-50 {
    opacity: 0.5;
}

.opac-60 {
    opacity: 0.6;
}

.opac-70 {
    opacity: 0.7;
}

.opac-80 {
    opacity: 0.8;
}

.opac-90 {
    opacity: 0.9;
}

.opac-100 {
    opacity: 1;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 14px;
}

@media (min-width: 1600px) {
    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 1550px;
    }
}
