@import "../../variables.scss";
@import "../../mixins.scss";

.event-header {
    border-bottom: 1px solid #f1f1f1;
    min-height: 70px;
    // box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: $background-color;
    .nav-box {
        padding-left: 0.6rem;
        padding-right: 0.8rem;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
    }
    .nav-link {
        padding: 0.3rem 0.7rem !important;
    }

    .navbar-nav {
        align-items: center;
    }

    .navbar-brand {
        max-width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
    }

    .header-avatar {
        display: inline-flex !important;
    }

    .header-avatar-image {
        display: contents !important;
    }

    .logo {
        height: 45px;
        @include respond-below(sm) {
            height: 35px;
        }
    }
}
